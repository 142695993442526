import gsap, { Circ } from "gsap";

const navAnimation = () => {
  const tl = gsap.timeline();
  tl.fromTo(
    ".navAnim",
    {
      y: -200,
    },
    {
      delay: 4,
      y: 0,
      duration: 1.4,
      ease: Circ.easeInOut,
    }
  );

  return tl;
};

export default navAnimation;
