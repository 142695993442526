import gsap, { Power3, Power4 } from "gsap";

const onceAnimation = () => {
  gsap.set(".active", {
    autoAlpha: 0,
  });
  gsap.set(".words-wrapper", {
    overflow: "unset",
  });
  // gsap.set("body", {
  //   maxHeight: "100.1vh",
  // });
  let i = 0;
  let txt = '"Don\'t think limits."';
  let speed = 55;
  setTimeout(() => {
    typeWriter();
  }, 1000);
  function typeWriter() {
    if (i < txt.length) {
      document.querySelector(".once-active").innerHTML += txt.charAt(i);
      i++;
      setTimeout(typeWriter, speed);
    }
  }
  const tween = gsap.to(".author", {
    autoAlpha: 1,
    delay: 2.4,
    duration: 1,
    repeat: 1,
    yoyo: true,
  });
  const tl = gsap.timeline({});
  tl.set("html", {
    cursor: "wait",
  })

    .to(
      ".black-cover",
      {
        yPercent: -100,
        //MODIFIE
        delay: 3.9,
        duration: 0.8,
        ease: Power4.easeInOut,
      },
      0
    )
    .to(
      ".once-active",
      {
        autoAlpha: 0,
        delay: 3.8,
        duration: 1.3,
        ease: Power3.easeOut,
      },
      0
    );

  tl.set(".words-wrapper", {
    //MAYBE HERE
    overflow: "hidden",
  })
    .set("html", {
      cursor: "auto",
    })
    .set("body", {
      maxHeight: "unset",
    });

  document.querySelector(".unactive") != null
    ? document.querySelector(".unactive").classList.remove("unactive")
    : null;

  return tl;
};

export default onceAnimation;
