import "whatwg-fetch";

const initMail = () => {
  document.querySelector("#myForm").addEventListener("submit", function (e) {
    e.preventDefault();

    sendMail();
    let button = document.querySelector("#sendButton");
    button.innerHTML = "Gesendet";
    button.style.backgroundColor = "#88ff7d";
    button.style.color = "#12470d";
    button.style.boxShadow = "0 3px 6px #daffd6, 0 2px 4px #daffd6";
  });

  const sendMail = () => {
    fetch("https://formsubmit.co/ajax/4a5beba226c52bdfc96e55c5f1ddf52d", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        name: document.querySelector("#vorname").value,
        lastname: document.querySelector("#nachname").value,
        number: document.querySelector("#number").value,
        email: document.querySelector("#email").value,
        message: document.querySelector("#message").value,
      }),
    })
      .then((response) => response.json())
      .then((data) => console.log())
      .catch((error) => console.log());
  };
};
export default initMail;
