import gsap, { Back } from "gsap";

const videoAnimation = (data) => {
  function delay(n) {
    n = n || 2000;
    return new Promise((done) => {
      setTimeout(() => {
        done();
      }, n);
    });
  }

  var lazyLoadInstance = new LazyLoad({
    // Your custom settings go here
  });

  const videoBg = data.next.container.querySelector(".video-modal-bg");
  const videoWrapper =
    data.next.container.querySelectorAll(".video-wrapper-big");

  let iframe;

  videoWrapper.forEach((element) => {
    element.addEventListener("click", async (e) => {
      console.log(e.target.dataset.video);
      iframe = data.next.container.querySelector(`#${e.target.dataset.video}`);
      console.log(iframe);
      videoBg.classList.toggle("active");
      gsap.to(".closeBtn", {
        autoAlpha: 1,
        delay: 0.7,
        y: -30,
        duration: 1,
        rotate: 90,
        ease: Back.easeInOut,
      });

      iframe.parentNode.parentNode.classList.toggle("active");
      document.querySelector("body").classList.toggle("blocked");
      console.log(iframe.dataset);
      iframe.dataset.src = iframe.dataset.src.replace(
        "autoplay=0",
        "autoplay=1"
      );

      iframe.src = iframe.src.replace("autoplay=0", "autoplay=1");
    });
  });

  videoBg.addEventListener("click", (e) => {
    gsap.set(".closeBtn", {
      autoAlpha: 0,
      y: 50,
      rotate: 0,
    });
    videoBg.classList.toggle("active");
    iframe.parentNode.parentNode.classList.toggle("active");
    document.querySelector("body").classList.toggle("blocked");

    iframe.src = iframe.src.replace("autoplay=1", "autoplay=0");
    iframe.dataset.src = iframe.dataset.src.replace("autoplay=1", "autoplay=0");
    // iframe.removeAttribute("src");
  });
};
export default videoAnimation;
