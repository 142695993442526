import gsap from "gsap";

const menuAnimationLeave = () => {
  const tl = gsap.timeline({
    defaults: { ease: "Power3.easeInOut", duration: 0.8 },
  });
  tl.to(".big-menu", {
    autoAlpha: 0,
    stagger: 0.1,
  });
  if (window.innerWidth < 1268) {
    tl.to(
      ".nav-bg-grey",
      {
        x: 800,
      },
      "-=.6"
    );
  } else {
    tl.to(
      ".nav-bg-grey",
      {
        xPercent: 250,
      },
      "-=.6"
    );
  }
  tl.to(
    ".nav-bg-white",
    {
      autoAlpha: 0,
    },
    "<"
  ).to(
    ".lower-wrapper a",
    {
      autoAlpha: 0,
      stagger: 0.1,
    },
    "<-.5"
  );
  return tl;
};

export default menuAnimationLeave;
