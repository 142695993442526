import gsap from "gsap";
import { menuAnimationEnter, menuAnimationLeave } from "../animations";

//go to anchor point
const initNavigation = () => {
  gsap.set(".nav-bg-white", {
    autoAlpha: 0,
  });
  gsap.set(".lower-wrapper a", {
    autoAlpha: 0,
    willChange: "opacity, visibility",
  });
  gsap.set(".big-menu", {
    autoAlpha: 0,
    willChange: "opacity, visibility",
  });

  const navbar = document.querySelector(".nav-fixed");
  const logo = document.querySelector(".navigation__logo svg");
  const burger = document.querySelector(".navigation__burger-wrapper");
  const main = document.querySelector("main");

  //bg navbar on scroll
  window.onscroll = () => {
    if (window.scrollY > 30) {
      navbar.classList.add("nav-active");
      logo?.classList.add("nav-active");
    } else {
      navbar.classList.remove("nav-active");
      logo?.classList.remove("nav-active");
    }
  };

  //burger animation on click
  let animationFinished;
  burger.addEventListener("click", async () => {
    if (animationFinished === false) return;
    animationFinished = false;
    //On close
    if (main.classList.contains("active")) {
      burger.classList.remove("active");
      await menuAnimationLeave();
      animationFinished = true;
      main.classList.remove("active");
      logo?.classList.remove("logo-menu-open");
    } else {
      //On open
      burger.classList.add("active");
      main.classList.add("active");
      logo?.classList.add("logo-menu-open");
      await menuAnimationEnter();
      animationFinished = true;
    }
  });
};

export default initNavigation;
