//IMPORTS

import barba from "@barba/core";

import "../sass/index.scss";
import "./utils/swiper";

import {
  initNavigation,
  initSwiper,
  smoothEffect,
  initWordTransition,
  initMail,
} from "./utils";

import {
  arrowsAnimation,
  navAnimation,
  onceAnimation,
  animPageLeave,
  enterAnimation,
  storyAnimation,
} from "./animations";
import videoAnimation from "./animations/videoAnimation";

//resets scroll history
if (history.scrollRestoration) {
  history.scrollRestoration = "manual";
}

function delay(n) {
  n = n || 2000;
  return new Promise((done) => {
    setTimeout(() => {
      done();
    }, n);
  });
}
let sm;
barba.init({
  sync: true,
  debug: false,
  timeout: 8000,
  transitions: [
    {
      name: "default",
      async once(data) {
        sm = smoothEffect(data);
        initNavigation();
        initSwiper();
        if (data.next.namespace !== "Kontakt") arrowsAnimation(data, 5000);
        if (data.next.namespace === "Videos") videoAnimation(data);
        if (data.next.namespace === "Meine Geschichte") storyAnimation();
        onceAnimation();
        enterAnimation(4.1);
        navAnimation();
        if (data.next.namespace === "Kontakt") initMail();
        // gsap.set(".black-cover", { yPercent: 100 });
      },
      async leave(data) {
        animPageLeave();
        await delay(500);
        data.current.container.remove();
      },

      enter(data) {
        initWordTransition(data);
        enterAnimation(0.9);
        if (data.next.namespace !== "Kontakt") arrowsAnimation(data, 800);
        if (data.next.namespace === "Videos") videoAnimation(data);
      },
      beforeEnter(data) {
        // sm.kill();
        sm = smoothEffect(data);
        initSwiper();
        initNavigation();
        if (data.next.namespace === "Kontakt") initMail();
        if (data.next.namespace === "Meine Geschichte") storyAnimation();
      },
    },
  ],
});
