import gsap, { Power3 } from "gsap";

const enterAnimation = (delay) => {
  gsap.set(".enter-a", {
    autoAlpha: 0,
    y: 80,
  });
  gsap.to(".enter-a", {
    autoAlpha: 1,
    y: 0,
    stagger: -0.05,
    duration: 0.9,
    delay: delay,
    ease: Power3.easeOut,
  });

  if (document.querySelector(".first-img")) {
    const tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: ".first-img",
        start: "top 45%",
        end: "center 70%",
        scrub: 1,
      },
    });

    tl2.to(".first-img", {
      autoAlpha: 0,
    });
    tl2.to(
      ".second-img",
      {
        autoAlpha: 1,
      },
      0
    );

    const tl3 = gsap.timeline({
      scrollTrigger: {
        trigger: ".first-img-m",
        start: "top 50%",
        end: "center 65%",
        id: "mobile",
        scrub: 1,
      },
    });

    tl3.to(".first-img-m", {
      autoAlpha: 0,
    });
    tl3.to(
      ".second-img-m",
      {
        autoAlpha: 1,
      },
      0
    );
  }
};
export default enterAnimation;
