import gsap from "gsap";
import ScrollSmoother from "gsap/ScrollSmoother";
import ScrollTrigger from "gsap/ScrollTrigger";

const smoothEffect = (data) => {
  gsap.registerPlugin(ScrollSmoother, ScrollTrigger);

  let sm = ScrollSmoother.create({
    content: "#content",
    smooth: 0.7,
    effects: true,
  });

  sm.scrollTo(0);

  const allLinks = document.querySelectorAll("a[href]");
  const burger = document.querySelector(".navigation__burger-wrapper");
  const main = document.querySelector("main");

  //link to hash from other page
  if (data.trigger?.dataset?.hash) {
    if (data.trigger.dataset.hash === "sponsor") {
      sm.scrollTo(`#${data.trigger.dataset.hash}`, false, "top 250px");
    } else sm.scrollTo(`#${data.trigger.dataset.hash}`, false);
  }

  allLinks.forEach((link) => {
    link.addEventListener("click", (e) => {
      if (
        e.currentTarget.href.split("#")[0] == window.location.href.split("#")[0]
      ) {
        if (e.target.dataset.hash === undefined) {
          sm.scrollTo(0, true);
        } else {
          if (e.target.dataset.hash === "sponsor")
            sm.scrollTo(`#${e.target.dataset.hash}`, false, "top 250px");
          else sm.scrollTo(`#${e.target.dataset.hash}`, false);
        }
        burger.classList.remove("active");
        main.classList.remove("active");

        e.preventDefault();
        e.stopPropagation();
      }
    });
  });

  return sm;
};
export default smoothEffect;
