import gsap, { Power2, Power3, Power4 } from "gsap";

const animPageLeave = () => {
  gsap.set(".once-active", {
    autoAlpha: 0,
  });
  const tl = gsap.timeline();
  tl.set(".black-cover", {
    yPercent: 100,
  })

    .set("html", {
      pointerEvents: "none",
    })
    .set("html", {
      cursor: "wait",
    })
    .set(".words-wrapper", {
      autoAlpha: 0,
      y: 40,
    })

    .to(".black-cover", {
      yPercent: 0,
      duration: 0.5,
      ease: Power4.easeIn,
    })
    // .set("body", {
    //   maxHeight: "100.1vh",
    // })
    .to(".words-wrapper", {
      autoAlpha: 1,
      duration: 0.9,
      y: -40,
      delay: 0.05,
      ease: Power2.easeOut,
    })
    .to(
      ".black-cover",
      {
        yPercent: -100,
        duration: 0.8,

        ease: Power4.easeInOut,
      },
      "-=.2"
    )
    .to(
      ".words",
      {
        yPercent: -100,
        duration: 0.7,
        ease: Power3.easeInOut,
      },
      "-=1"
    )
    .set(".words", {
      yPercent: 0,
    })
    .set(".words-wrapper", {
      autoAlpha: 0,
    })
    .set(
      "html",
      {
        cursor: "auto",
      },
      "-=.3"
    )
    .set(
      "html",
      {
        pointerEvents: "unset",
      },
      "-=.3"
    )

    .set(
      "body",
      {
        maxHeight: "unset",
      },
      "-=.3"
    );

  return tl;
};

export default animPageLeave;
