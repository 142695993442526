import Swiper from "swiper/swiper-bundle.esm.js";
import "swiper/swiper-bundle.css";

const getSwiper = () => {
  document.addEventListener("DOMContentLoaded", function () {
    // Function to dynamically add the script
    function addPlausibleScript() {
      var script = document.createElement("script");
      script.defer = true;
      script.setAttribute("data-domain", "chiaramesserli.ch");
      script.src = "/script.js";
      document.head.appendChild(script);

      // Remove event listeners after script is added
      document.removeEventListener("mousemove", addPlausibleScript);
      document.removeEventListener("scroll", addPlausibleScript);
    }

    // Add event listeners for mouse move and scroll
    document.addEventListener("mousemove", addPlausibleScript);
    document.addEventListener("scroll", addPlausibleScript);
  });
  new Swiper(".mySwiper", {
    cssMode: false,
    spaceBetween: 30,
    loop: true,
    // autoHeight: true,
    effect: "slide",
    speed: 400,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },

    mousewheel: false,
    keyboard: true,
  });

  var swiper = new Swiper(".mySwiperSecond", {
    effect: "coverflow",
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: "auto",
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
    pagination: {
      el: ".swiper-pagination",
    },
  });
};

export default getSwiper;
