import gsap from "gsap";

const menuAnimationEnter = () => {
  gsap.set(".nav-bg-white", {
    autoAlpha: 0,
  });
  gsap.set(".lower-wrapper a", {
    autoAlpha: 0,
  });
  gsap.set(".big-menu", {
    autoAlpha: 0,
  });
  gsap.set(".nav-bg-grey", {
    x: 0,
    xPercent: 0,
  });
  const tl = gsap.timeline({
    defaults: { ease: "Power3.easeInOut", duration: 0.8 },
  });

  if (window.innerWidth < 1268) {
    tl.from(
      ".nav-bg-grey",
      {
        x: 800,
      },
      0
    );
  } else {
    tl.from(
      ".nav-bg-grey",
      {
        xPercent: 250,
      },
      0
    );
  }
  tl.to(
    ".nav-bg-white",
    {
      autoAlpha: 1,
    },
    0
  )
    .to(
      ".big-menu",
      {
        autoAlpha: 1,
        stagger: 0.2,
        delay: 0,
      },
      0
    )
    .to(
      ".lower-wrapper a ",
      {
        autoAlpha: 1,
        stagger: 0.1,
      },
      0
    );

  return tl;
};

export default menuAnimationEnter;
