import gsap, { Power4 } from "gsap";

const StoryAnimation = () => {
  let covers = document.querySelectorAll(".white-cover");
  covers.forEach((cover) => {
    gsap.to(cover, {
      yPercent: 100,
      duration: 1.3,
      ease: Power4.easeInOut,
      scrollTrigger: {
        trigger: cover,
        start: "top 80%",
      },
    });
  });

  document.querySelectorAll(".story-section p").forEach((p) => {
    gsap.from(p, {
      autoAlpha: 0,
      y: 50,
      duration: 1,
      ease: Power4.easeInOut,
      scrollTrigger: {
        trigger: p,
        start: "top 80%",
      },
    });
  });

  document.querySelectorAll(".observedCount").forEach((title) => {
    gsap.from(title, {
      autoAlpha: 0,
      y: 50,
      duration: 1,
      ease: Power4.easeInOut,
      scrollTrigger: {
        trigger: title,
        start: "top 80%",
      },
    });
  });

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: ".story-section__year2016",
      start: "top 80%",
      end: "+=100",
      scrub: 1,
    },
  });

  tl.to('[data-barba-namespace="Meine Geschichte"] #content', {
    backgroundColor: "#1c1e21",
  })
    .to(
      ".story-section svg rect",
      {
        fill: "#23262a",
      },
      0
    )
    .to(
      ".story-section .decoration-svg path",
      {
        fill: "#23262a",
      },
      0
    )
    .to(
      ".stroke-deco path",
      {
        stroke: "#23262a",
      },
      0
    )
    .to(
      ".story-section .white-cover",
      {
        backgroundColor: "#1c1e21",
      },
      0
    )
    .to(
      ".story-section",
      {
        color: "white",
      },
      0
    );
};
export default StoryAnimation;
