import gsap, { Linear } from "gsap";

const arrowsAnimation = ({ next }, to) => {
  setTimeout(() => {
    for (let index = 1; index <= 4; index++) {
      gsap.to(`.arrow-anim${index}`, {
        fill: next.namespace === "Videos" ? "white" : "rgba(139, 144, 152, .8)",

        ease: Linear,
        delay: 1.2 + index * 0.2,
        duration: 0.3,
        repeat: -1,
        repeatDelay: 1.5,
      });

      gsap.to(`.arrow-anim${index}`, {
        fill: "transparent",
        ease: Linear,
        duration: 0.3,
        delay: 1.9 + index * 0.2,
        repeat: -1,
        repeatDelay: 1.5,
      });
    }
  }, to);
};

export default arrowsAnimation;
